import {
	CognitoUserPool,
	CognitoUser,
	AuthenticationDetails,
	CognitoUserAttribute,
} from "amazon-cognito-identity-js";

const poolData = {
	UserPoolId: "us-east-1_baikoIyQI",
	ClientId: "3k4n33e2lqomh8n02bnnhib7ut",
};

const userPool = new CognitoUserPool(poolData);

export const signUp = (Email, Password) => {
	const attributeList = [];

	const dataEmail = {
		Name: "email",
		Value: Email,
	};

	const attributeEmail = new CognitoUserAttribute(dataEmail);
	attributeList.push(attributeEmail);

	return new Promise((resolve, reject) => {
		userPool.signUp(Email, Password, attributeList, null, (err, result) => {
			if (err) {
				reject(err);
			} else {
				resolve(result);
			}
		});
	});
};

export const confirmSignUp = (Email, code) => {
	const userData = {
		Username: Email,
		Pool: userPool,
	};

	const cognitoUser = new CognitoUser(userData);

	return new Promise((resolve, reject) => {
		cognitoUser.confirmRegistration(code, true, (err, result) => {
			if (err) {
				reject(err);
			} else {
				resolve(result);
			}
		});
	});
};

export const signIn = (Email, Password) => {
	const authenticationDetails = new AuthenticationDetails({
		Username: Email,
		Password,
	});

	const userData = {
		Username: Email,
		Pool: userPool,
	};

	const cognitoUser = new CognitoUser(userData);

	return new Promise((resolve, reject) => {
		cognitoUser.authenticateUser(authenticationDetails, {
			onSuccess: (result) => {
				console.log("Authentication successful", result);
				resolve(result);
			},
			onFailure: (err) => {
				console.log("Authentication failed", err);
				reject(err);
			},
		});
	});
};

export const getCurrentUser = () => {
	return userPool.getCurrentUser();
};

export const signOut = () => {
	const user = getCurrentUser();
	if (user) {
		user.signOut(() => {
			console.log("User signed out");
		});
	}
};

import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Box, Button, TextField, Typography, Paper } from "@mui/material";
import { signIn } from "../services/cognitoService";

interface LoginPageProps {
	checkAuthAndTerms: () => void;
}

const LoginPage: React.FC<LoginPageProps> = ({ checkAuthAndTerms }) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");
	const navigate = useNavigate();

	const handleLogin = async () => {
		console.log("Attempting login...");
		try {
			const result = await signIn(email, password);
			console.log("Login successful:", result);
			checkAuthAndTerms();
			navigate("/terms"); // Navigate to terms after login
		} catch (err: unknown) {
			console.log("Login failed:", err);
			if (err instanceof Error) {
				setError(
					err.message || "Failed to login. Please check your credentials."
				);
			} else {
				setError("An unexpected error occurred. Please try again.");
			}
		}
	};

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
				backgroundColor: "#f5f5f5",
			}}
		>
			<Paper
				elevation={3}
				sx={{
					p: 4,
					width: "350px",
					borderRadius: "12px", // Rounded corners for the form container
					boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Subtle shadow for a polished look
				}}
			>
				<Typography
					variant="h5"
					sx={{ mb: 3, textAlign: "center", color: "#002255" }}
				>
					Login
				</Typography>
				<TextField
					label="Email"
					variant="outlined"
					fullWidth
					sx={{
						mb: 2,
						"& .MuiOutlinedInput-root": {
							borderRadius: "20px", // Rounded corners for input fields
							"& fieldset": {
								borderColor: "gray",
							},
							"&:hover fieldset": {
								borderColor: "#002255",
							},
							"&.Mui-focused fieldset": {
								borderColor: "#002255",
							},
						},
						"& .MuiInputLabel-root": {
							color: "#002255",
						},
						"& .MuiInputLabel-root.Mui-focused": {
							color: "#002255",
						},
					}}
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
				<TextField
					label="Password"
					variant="outlined"
					type="password"
					fullWidth
					sx={{
						mb: 2,
						"& .MuiOutlinedInput-root": {
							borderRadius: "20px", // Rounded corners for input fields
							"& fieldset": {
								borderColor: "gray",
							},
							"&:hover fieldset": {
								borderColor: "#002255",
							},
							"&.Mui-focused fieldset": {
								borderColor: "#002255",
							},
						},
						"& .MuiInputLabel-root": {
							color: "#002255",
						},
						"& .MuiInputLabel-root.Mui-focused": {
							color: "#002255",
						},
					}}
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
				{error && (
					<Typography color="error" sx={{ mb: 2 }}>
						{error}
					</Typography>
				)}
				<Button
					variant="contained"
					sx={{
						backgroundColor: "#002255",
						"&:hover": {
							backgroundColor: "#000a63",
						},
						borderRadius: "20px", // Rounded corners for buttons
						py: 1.5, // Increase padding for a more substantial button
						mt: 2,
					}}
					fullWidth
					onClick={handleLogin}
				>
					Login
				</Button>
				<Typography variant="body2" sx={{ mt: 3, textAlign: "center" }}>
					Don't have an account?{" "}
					<Link
						to="/signup"
						style={{ color: "#002255", textDecoration: "none" }}
					>
						Sign up here
					</Link>
				</Typography>
			</Paper>
		</Box>
	);
};

export default LoginPage;

import React, { useEffect, useState } from "react";
import {
	Routes,
	Route,
	Navigate,
	useLocation,
	useNavigate,
	Link,
} from "react-router-dom";
import {
	Box,
	CssBaseline,
	Container,
	AppBar,
	Toolbar,
	Typography,
	Paper,
} from "@mui/material";
import ChatBox from "./components/ChatBox";
import LoginPage from "./components/LoginPage";
import SignUpPage from "./components/SignUpPage";
import ConfirmSignUpPage from "./components/ConfirmSignUpPage";
import TermsAndConditionsPage from "./components/TermsAndConditionsPage";
import UploadPdf from "./components/UploadPdf"; // Import the UploadPdf component
import { getCurrentUser, signOut } from "./services/cognitoService";
import logo from "./assets/logo.png";

const App: React.FC = () => {
	const [authenticated, setAuthenticated] = useState<boolean | null>(null);
	const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
	const [email, setEmail] = useState<string>("");
	const location = useLocation();
	const navigate = useNavigate();

	const checkAuthAndTerms = () => {
		const user = getCurrentUser();
		if (user) {
			user.getSession((err: any, session: any) => {
				if (err || !session.isValid()) {
					setAuthenticated(false);
				} else {
					setAuthenticated(true);
					const termsAccepted = localStorage.getItem("termsAccepted");
					setTermsAccepted(termsAccepted === "true");
					setEmail(user.getUsername());
				}
			});
		} else {
			setAuthenticated(false);
		}
	};

	useEffect(() => {
		checkAuthAndTerms();
	}, []);

	useEffect(() => {
		if (
			authenticated === false &&
			location.pathname !== "/login" &&
			location.pathname !== "/signup" &&
			location.pathname !== "/confirm-signup" &&
			location.pathname !== "/terms"
		) {
			navigate("/login");
		} else if (
			authenticated &&
			!termsAccepted &&
			location.pathname !== "/terms"
		) {
			navigate("/terms");
		}
	}, [authenticated, termsAccepted, location.pathname, navigate]);

	const handleSignOut = () => {
		signOut();
		localStorage.removeItem("termsAccepted");
		setAuthenticated(false);
		setTermsAccepted(false);
		navigate("/login");
	};

	const selectedDirectory = "gsop";

	return (
		<Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
			<CssBaseline />
			<AppBar
				position="fixed"
				sx={{
					backgroundColor: "#FFFFFF",
					color: "#000000",
					borderBottom: "2px solid #002255",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
			>
				<Toolbar sx={{ justifyContent: "space-between", padding: "0 20px" }}>
					<Box display="flex" alignItems="center">
						<img
							src={logo}
							alt="GSOP Logo"
							style={{ height: "60px", cursor: "pointer", margin: "8px 0" }}
							onClick={() => navigate("/")} // Redirect to home on click
						/>
						<Link to="/" style={{ textDecoration: "none", color: "#002255" }}>
							<Typography variant="h6" sx={{ ml: 2, fontWeight: "bold" }}>
								GSOP Rules!
							</Typography>
						</Link>
					</Box>
					<Box display="flex" alignItems="center" gap={4}>
						<Typography
							variant="body2"
							sx={{
								cursor: "pointer",
								color: "#002255",
								textDecoration: "none",
								"&:hover": { textDecoration: "underline" },
							}}
							onClick={() => navigate("/upload")} // Link to the upload page
						>
							Manage Policies
						</Typography>
						<Typography
							variant="body2"
							sx={{
								cursor: "pointer",
								color: "#002255",
								textDecoration: "none",
								"&:hover": { textDecoration: "underline" },
							}}
							onClick={handleSignOut}
						>
							Sign Out
						</Typography>
					</Box>
				</Toolbar>
			</AppBar>

			<Box
				component="main"
				sx={{
					flex: 1,
					display: "flex",
					flexDirection: "column",
					paddingTop: "80px", // Adjusted padding to add more space below the AppBar
					paddingBottom: "64px", // Space for the footer
					overflow: "auto", // Allow the main content to scroll if it overflows
					marginTop: "20px", // Margin to ensure space between the AppBar and the content
				}}
			>
				<Routes>
					<Route path="/signup" element={<SignUpPage />} />
					<Route
						path="/login"
						element={<LoginPage checkAuthAndTerms={checkAuthAndTerms} />}
					/>
					<Route path="/confirm-signup" element={<ConfirmSignUpPage />} />
					<Route
						path="/terms"
						element={
							<TermsAndConditionsPage checkAuthAndTerms={checkAuthAndTerms} />
						}
					/>
					<Route
						path="/"
						element={
							authenticated ? (
								<ChatBox
									selectedDirectory={selectedDirectory}
									userEmail={email}
								/>
							) : (
								<Navigate to="/login" />
							)
						}
					/>
					<Route path="/upload" element={<UploadPdf />} />
					<Route path="*" element={<Navigate to="/login" />} />
				</Routes>
			</Box>

			<Paper
				component="footer"
				square
				sx={{
					p: 2,
					backgroundColor: "#FFFFFF",
					color: "#000000",
					borderTop: "2px solid #002255",
					position: "fixed",
					bottom: 0,
					width: "100%",
				}}
			>
				<Container maxWidth="lg">
					<Box
						display="flex"
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
					>
						<Typography variant="body1">© 2024 GSOP Rules!</Typography>
					</Box>
				</Container>
			</Paper>
		</Box>
	);
};

export default App;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	Container,
	Typography,
	Paper,
	Checkbox,
	FormControlLabel,
	Button,
	Box,
} from "@mui/material";

interface TermsAndConditionsPageProps {
	checkAuthAndTerms: () => void;
}

const TermsAndConditionsPage: React.FC<TermsAndConditionsPageProps> = ({
	checkAuthAndTerms,
}) => {
	const [agreed, setAgreed] = useState(false);
	const navigate = useNavigate();

	const handleAgree = () => {
		if (agreed) {
			localStorage.setItem("termsAccepted", "true");
			checkAuthAndTerms();
			navigate("/");
		}
	};

	const handleDisagree = () => {
		localStorage.removeItem("termsAccepted");
		navigate("/login");
	};

	return (
		<Container
			maxWidth="md"
			sx={{
				mt: 4,
				mb: 4,
				display: "flex",
				flexDirection: "column",
				height: "calc(100vh - 128px)", // Adjust based on header and footer height
				overflowY: "auto", // Enable scrolling for the container
				fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif", // Modern, professional font
			}}
		>
			<Paper
				elevation={3}
				sx={{
					p: 4,
					flex: 1,
					overflowY: "auto", // Ensure the Paper component scrolls if needed
					borderRadius: "16px", // Rounded corners for the paper
				}}
			>
				<Typography
					variant="h5"
					gutterBottom
					sx={{ fontWeight: "bold", color: "#002255" }}
				>
					Terms and Conditions
				</Typography>
				<Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
					Section I: Purpose
				</Typography>
				<Typography variant="body2" paragraph>
					The primary purpose of <i>GSOP Rules!</i> is to serve as a resource
					for Lloyd L. Gregory School of Pharmacy (GSOP) faculty in searching
					and navigating GSOP policies.
				</Typography>
				<Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
					Section II: Acknowledgement of Limitations
				</Typography>
				<Typography variant="body2" paragraph>
					Users are reminded that all answers provided by <i>GSOP Rules!</i>{" "}
					should be verified by review of the relevant GSOP policies. Users are
					also reminded that <i>GSOP Rules!</i> may not have the most recently
					adopted GSOP policies. The onus is on users to verify the information
					provided by
					<i>GSOP Rules!</i>.
				</Typography>
				<Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
					Section III: Disclaimer on Medical Advice
				</Typography>
				<Typography variant="body2" paragraph>
					The information provided by <i>GSOP Rules!</i> is intended solely for
					educational and informational purposes. It should not be considered as
					medical, mental health, or professional advice.
				</Typography>
				<Typography variant="body2" paragraph>
					If you are experiencing any form of physical or mental health issues,
					we strongly urge you to seek immediate help from a qualified
					healthcare provider or mental health professional. In cases of medical
					emergencies, please contact emergency services or visit the nearest
					hospital.
				</Typography>
				<Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
					Section IV: Understanding
				</Typography>
				<Typography variant="body2" paragraph>
					By using <i>GSOP Rules!</i>, users acknowledge and agree to the
					following terms of use:
				</Typography>

				<Typography variant="body2" paragraph>
					1. Users are at least age 18.
				</Typography>
				<Typography variant="body2" paragraph>
					2. Users are employed at Palm Beach Atlantic University's Lloyd L.
					Gregory School of Pharmacy (GSOP).
				</Typography>
				<Typography variant="body2" paragraph>
					3. Users can be banned for any violation of these terms of use.
				</Typography>
				<FormControlLabel
					control={
						<Checkbox
							checked={agreed}
							onChange={(e) => setAgreed(e.target.checked)}
						/>
					}
					label="I Agree to the Terms of Use"
					sx={{ color: "#002255", mb: 2 }}
				/>
				<Box sx={{ textAlign: "center" }}>
					<Button
						variant="contained"
						sx={{
							backgroundColor: "#002255",
							"&:hover": {
								backgroundColor: "#000a63",
							},
							borderRadius: "20px", // Rounded corners for the button
							padding: "6px 16px", // Smaller button size
						}}
						onClick={handleAgree}
						disabled={!agreed}
					>
						Proceed
					</Button>
					<Button
						variant="contained"
						sx={{
							backgroundColor: "#002255",
							"&:hover": {
								backgroundColor: "#000a63",
							},
							borderRadius: "20px", // Rounded corners for the button
							ml: 2, // Add some margin to separate buttons
							padding: "6px 16px", // Smaller button size
						}}
						onClick={handleDisagree}
					>
						Disagree
					</Button>
				</Box>
			</Paper>
		</Container>
	);
};

export default TermsAndConditionsPage;

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
	Box,
	Button,
	Typography,
	CircularProgress,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Paper,
	Tooltip,
	IconButton,
	Collapse,
	Alert,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { listS3FoldersAndUrls } from "../services/lambdaService";

// Function to clean the title and return as a string
const cleanTitle = (fileName: string): string => {
	let cleanedTitle = fileName
		.replace(/Author%3A/g, "")
		.replace(/Type%3A/g, ".")
		.replace(/Year%3A/g, ".")
		.replace(/Title%3A/g, ".")
		.replace(/Author:/g, "")
		.replace(/Type:/g, ".")
		.replace(/Year:/g, ".")
		.replace(/Title:/g, ".")
		.replace(/Author-/g, "")
		.replace(/Type-/g, ".")
		.replace(/Year-/g, ".")
		.replace(/Title-/g, ".")
		.trim();

	cleanedTitle = cleanedTitle.replace(/\s*\.\s*/g, ".");
	cleanedTitle = cleanedTitle.replace(/\./g, ". ");

	return cleanedTitle;
};

// Sorted years array (most recent to oldest)
const policyYears = [
	2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015,
].sort((a, b) => b - a);

const UploadPdf = () => {
	const [file, setFile] = useState<File | null>(null);
	const [uploading, setUploading] = useState<boolean>(false);
	const [deleting, setDeleting] = useState<boolean>(false);
	const [uploadingConfirm, setUploadingConfirm] = useState<boolean>(false);
	const [deletingConfirm, setDeletingConfirm] = useState<boolean>(false);
	const [message, setMessage] = useState<{
		text: string;
		severity: "success" | "error" | "info" | "warning";
	} | null>(null);
	const [policyOriginator, setPolicyOriginator] = useState<string>("");
	const [policyType, setPolicyType] = useState<string>("");
	const [policyYear, setPolicyYear] = useState<string>("");
	const [s3Files, setS3Files] = useState<
		Array<{ file_name: string; url: string }>
	>([]);
	const [selectedFile, setSelectedFile] = useState<string>("");
	const [refresh, setRefresh] = useState<boolean>(false);
	const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
	const [uploadConfirmOpen, setUploadConfirmOpen] = useState<boolean>(false);
	const [fileToDelete, setFileToDelete] = useState<string>("");

	const triggerRefresh = () => {
		console.log("Triggering refresh");
		setRefresh(!refresh);
	};

	// Fetch the list of files from S3 when the component mounts
	useEffect(() => {
		const fetchS3Files = async () => {
			try {
				console.log("Fetching list of files from S3");
				const response = await listS3FoldersAndUrls();
				const files = response.pdf_files || []; // Extract the files array
				setS3Files(files);
				console.log("Files fetched from S3:", files);
			} catch (error) {
				console.error("Error fetching files from S3:", error);
				setMessage({
					text: "There was an error fetching files from S3.",
					severity: "error",
				});
			}
		};

		fetchS3Files();
	}, [refresh]);

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			setFile(e.target.files[0]);
			console.log("File selected:", e.target.files[0].name);
		} else {
			console.log("No file selected");
		}
	};

	const handleUploadClick = () => {
		console.log("Upload button clicked");
		if (!file || !policyOriginator || !policyType || !policyYear) {
			setMessage({
				text: "Please select a file and fill in all fields.",
				severity: "warning",
			});
			console.warn("Upload attempt with missing fields");
			return;
		}

		console.log("Opening upload confirmation dialog");
		setUploadConfirmOpen(true);
	};

	// Assuming 'fields' and 'url' are obtained from your backend
	const handleConfirmUpload = async () => {
		console.log("Upload confirmed");

		if (!file) {
			setMessage({
				text: "File is missing. Please select a file before uploading.",
				severity: "error",
			});
			console.error("Upload confirmed, but no file is selected");
			return;
		}

		setUploadingConfirm(true);
		setUploading(true);
		setMessage(null);

		const cleanedFileName = file.name.replace(/[:/]/g, "-");
		const customFileName = `Author:${policyOriginator}_Type:${policyType}_Year:${policyYear}_Title:${cleanedFileName}`;
		console.log("Custom file name generated:", customFileName);

		try {
			console.log(
				"Requesting presigned POST URL with custom file name:",
				customFileName
			);

			const presignedUrlResponse = await axios.post(
				"https://c4ufhe0ncj.execute-api.us-east-1.amazonaws.com/prod/generate-presigned-url-upload",
				{
					file_name: customFileName, // Send cleaned and unencoded filename
				}
			);

			const { url, fields, key } = presignedUrlResponse.data;
			console.log("Received presigned POST URL:", url);
			console.log("Received key:", key);

			const formData = new FormData();
			Object.entries(fields).forEach(([fieldKey, value]) => {
				formData.append(fieldKey, value as string); // Casting to string
			});
			formData.append("file", file);

			console.log("Uploading file to S3 using presigned POST URL...");
			const response = await axios.post(url, formData);
			console.log("Upload response:", response);

			setMessage({
				text: `File uploaded successfully as: "${policyOriginator} ${policyType} ${policyYear} ${file.name}"`,
				severity: "success",
			});

			setFile(null);
			setPolicyOriginator("");
			setPolicyType("");
			setPolicyYear("");

			const fileInputElement = document.getElementById(
				"file-upload"
			) as HTMLInputElement | null;
			if (fileInputElement) {
				fileInputElement.value = ""; // Reset the input value
			}

			triggerRefresh();
		} catch (error) {
			console.error("Error during upload:", error);
			if (axios.isAxiosError(error)) {
				console.error("Axios error response:", error.response?.data);
			}
			setMessage({
				text: "There was an error uploading your file. Please try again.",
				severity: "error",
			});
		} finally {
			setUploading(false);
			setUploadingConfirm(false);
			setUploadConfirmOpen(false);
		}
	};

	const handleCancelUpload = () => {
		console.log("Upload canceled by user");
		setUploadConfirmOpen(false);
		setFile(null);
		setPolicyOriginator("");
		setPolicyType("");
		setPolicyYear("");
	};

	const handleDeleteClick = () => {
		console.log("Delete button clicked");
		if (!selectedFile) {
			setMessage({
				text: "Please select a file to delete.",
				severity: "warning",
			});
			console.warn("Delete attempt with no file selected");
			return;
		}

		console.log("Opening delete confirmation dialog for file:", selectedFile);
		setFileToDelete(selectedFile);
		setConfirmOpen(true);
	};

	const handleConfirmDelete = async () => {
		console.log("Delete confirmed for file:", fileToDelete);
		setDeletingConfirm(true);
		setDeleting(true);
		setMessage(null);

		try {
			await axios.delete(
				"https://c4ufhe0ncj.execute-api.us-east-1.amazonaws.com/prod/delete-pdf",
				{
					data: {
						file_name: fileToDelete,
					},
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			console.log("File deleted successfully:", fileToDelete);
			setMessage({
				text: `File "${cleanTitle(fileToDelete)}" deleted successfully.`,
				severity: "success",
			});

			triggerRefresh();
			setSelectedFile("");
		} catch (error) {
			console.error("Error deleting file:", error);
			setMessage({
				text: "There was an error deleting your file. Please try again.",
				severity: "error",
			});
		} finally {
			setDeleting(false);
			setDeletingConfirm(false);
			setConfirmOpen(false);
			setFileToDelete("");
		}
	};

	const handleCancelDelete = () => {
		console.log("Delete canceled by user");
		setConfirmOpen(false);
		setSelectedFile("");
	};

	return (
		<>
			{/* Alert Section */}
			{message && (
				<Box
					sx={{ position: "fixed", top: 0, left: 0, right: 0, zIndex: 9999 }}
				>
					<Collapse in={Boolean(message)}>
						<Alert
							severity={message.severity}
							onClose={() => setMessage(null)}
							sx={{ borderRadius: 0 }}
						>
							{message.text}
						</Alert>
					</Collapse>
				</Box>
			)}

			<Box
				sx={{
					maxWidth: 1200,
					margin: "auto",
					padding: 2,
					display: "flex",
					flexDirection: { xs: "column", md: "row" },
					justifyContent: "space-between",
					gap: 2,
				}}
			>
				{/* Upload Section */}
				<Paper
					elevation={3}
					sx={{
						padding: 2,
						borderRadius: "20px",
						flex: 1,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						marginBottom: { xs: 2, md: 0 },
					}}
				>
					<Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
						<Typography variant="h5" sx={{ marginRight: 1 }}>
							Upload Policy
						</Typography>
						<Tooltip title="Upload a policy to include in GSOP Rules! knowledge.">
							<IconButton>
								<InfoOutlinedIcon />
							</IconButton>
						</Tooltip>
					</Box>

					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							marginBottom: 2,
							width: "100%",
						}}
					>
						<FormControl sx={{ minWidth: 200, marginBottom: 2 }}>
							<InputLabel id="policy-originator-label">
								Policy Originator
							</InputLabel>
							<Select
								labelId="policy-originator-label"
								value={policyOriginator}
								onChange={(e) => setPolicyOriginator(e.target.value)}
								label="Policy Originator"
								sx={{
									width: "100%",
									maxWidth: 300,
									borderRadius: "20px",
									borderColor: "gray",
									"& .MuiOutlinedInput-root": {
										"& fieldset": {
											borderColor: "gray",
										},
										"&:hover fieldset": {
											borderColor: "#002255",
										},
										"&.Mui-focused fieldset": {
											borderColor: "#002255",
										},
									},
									"& .MuiInputLabel-root": {
										color: "#002255",
									},
									"& .MuiInputLabel-root.Mui-focused": {
										color: "#002255",
									},
								}}
							>
								<MenuItem value="PBA">PBA</MenuItem>
								<MenuItem value="GSOP">GSOP</MenuItem>
							</Select>
						</FormControl>

						<FormControl sx={{ minWidth: 200, marginBottom: 2 }}>
							<InputLabel id="policy-type-label">Policy Type</InputLabel>
							<Select
								labelId="policy-type-label"
								value={policyType}
								onChange={(e) => setPolicyType(e.target.value)}
								label="Policy Type"
								sx={{
									width: "100%",
									maxWidth: 300,
									borderRadius: "20px",
									borderColor: "gray",
									"& .MuiOutlinedInput-root": {
										"& fieldset": {
											borderColor: "gray",
										},
										"&:hover fieldset": {
											borderColor: "#002255",
										},
										"&.Mui-focused fieldset": {
											borderColor: "#002255",
										},
									},
									"& .MuiInputLabel-root": {
										color: "#002255",
									},
									"& .MuiInputLabel-root.Mui-focused": {
										color: "#002255",
									},
								}}
							>
								<MenuItem value="Academic">Academic</MenuItem>
								<MenuItem value="Experiential">Experiential</MenuItem>
								<MenuItem value="Administrative">Administrative</MenuItem>
								<MenuItem value="Students">Students</MenuItem>
							</Select>
						</FormControl>

						<FormControl sx={{ minWidth: 200, marginBottom: 2 }}>
							<InputLabel id="policy-year-label">
								Policy Adopted Year
							</InputLabel>
							<Select
								labelId="policy-year-label"
								value={policyYear}
								onChange={(e) => setPolicyYear(e.target.value)}
								label="Policy Adopted Year"
								sx={{
									width: "100%",
									maxWidth: 300,
									borderRadius: "20px",
									borderColor: "gray",
									"& .MuiOutlinedInput-root": {
										"& fieldset": {
											borderColor: "gray",
										},
										"&:hover fieldset": {
											borderColor: "#002255",
										},
										"&.Mui-focused fieldset": {
											borderColor: "#002255",
										},
									},
									"& .MuiInputLabel-root": {
										color: "#002255",
									},
									"& .MuiInputLabel-root.Mui-focused": {
										color: "#002255",
									},
								}}
							>
								{policyYears.map((year) => (
									<MenuItem key={year} value={year.toString()}>
										{year}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>

					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							width: "100%",
						}}
					>
						<input
							type="file"
							accept="application/pdf"
							onChange={handleFileChange}
							id="file-upload"
							style={{ display: "none" }}
						/>
						<label htmlFor="file-upload">
							<Button
								variant="contained"
								component="span"
								sx={{
									backgroundColor: "#002255",
									color: "#FFFFFF",
									borderRadius: "20px",
									padding: "10px 20px",
									marginBottom: 2,
									whiteSpace: "nowrap",
									maxWidth: "100%",
									overflow: "hidden",
									textOverflow: "ellipsis",
								}}
							>
								{file ? file.name : "Choose PDF"}
							</Button>
						</label>

						<Button
							variant="contained"
							onClick={handleUploadClick}
							disabled={!file || uploading}
							sx={{
								backgroundColor: "#002255",
								color: "#FFFFFF",
								borderRadius: "20px",
								padding: "10px 20px",
								width: 200,
								"&:hover": {
									backgroundColor: "#000a63",
								},
							}}
						>
							{uploading ? (
								<CircularProgress size={24} color="inherit" />
							) : (
								"Upload"
							)}
						</Button>
					</Box>
				</Paper>

				{/* Delete Section */}
				<Paper
					elevation={3}
					sx={{
						padding: 2,
						borderRadius: "20px",
						flex: 1,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						marginBottom: { xs: 2, md: 0 },
					}}
				>
					<Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
						<Typography variant="h5" sx={{ marginRight: 1 }}>
							Delete Policy
						</Typography>
						<Tooltip title="Delete a policy from GSOP Rules! knowledge.">
							<IconButton>
								<InfoOutlinedIcon />
							</IconButton>
						</Tooltip>
					</Box>

					<FormControl sx={{ minWidth: 200, marginBottom: 2, width: "100%" }}>
						<InputLabel id="select-file-label">Select File</InputLabel>
						<Select
							labelId="select-file-label"
							value={selectedFile}
							onChange={(e) => setSelectedFile(e.target.value)}
							label="Select File"
							sx={{
								width: "100%",
								maxWidth: 300,
								borderRadius: "20px",
								borderColor: "gray",
								"& .MuiOutlinedInput-root": {
									"& fieldset": {
										borderColor: "gray",
									},
									"&:hover fieldset": {
										borderColor: "#002255",
									},
									"&.Mui-focused fieldset": {
										borderColor: "#002255",
									},
								},
								"& .MuiInputLabel-root": {
									color: "#002255",
								},
								"& .MuiInputLabel-root.Mui-focused": {
									color: "#002255",
								},
							}}
						>
							{s3Files.map((file) => (
								<MenuItem key={file.file_name} value={file.file_name}>
									{cleanTitle(file.file_name)} {/* Display cleaned up name */}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<Button
						variant="contained"
						onClick={handleDeleteClick}
						disabled={!selectedFile || deleting}
						sx={{
							backgroundColor: "#002255",
							color: "#FFFFFF",
							borderRadius: "20px",
							padding: "10px 20px",
							width: 200,
							"&:hover": {
								backgroundColor: "#000a63",
							},
						}}
					>
						{deleting ? (
							<CircularProgress size={24} color="inherit" />
						) : (
							"Delete"
						)}
					</Button>
				</Paper>
			</Box>

			{/* Upload Confirmation Dialog */}
			<Dialog open={uploadConfirmOpen} onClose={handleCancelUpload}>
				<DialogTitle>Confirm Upload</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Did you rename your PDF to include only the title?
						<br />
						<br />
						Please see the example below.
						<br />
						<br />
						Original name:
						<br />
						"IPE_POLICY(2010)-FINAL.pdf"
						<br />
						<br />
						Renamed:
						<br />
						"IPE POLICY.pdf"
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleConfirmUpload}
						color="primary"
						autoFocus
						disabled={uploadingConfirm}
					>
						{uploadingConfirm ? (
							<CircularProgress size={24} color="inherit" />
						) : (
							"Yes"
						)}
					</Button>
					<Button onClick={handleCancelUpload} color="primary">
						No
					</Button>
				</DialogActions>
			</Dialog>

			{/* Delete Confirmation Dialog */}
			<Dialog open={confirmOpen} onClose={handleCancelDelete}>
				<DialogTitle>Confirm Delete</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to permanently delete "
						{cleanTitle(selectedFile)}"?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleConfirmDelete}
						color="primary"
						autoFocus
						disabled={deletingConfirm}
					>
						{deletingConfirm ? (
							<CircularProgress size={24} color="inherit" />
						) : (
							"Yes"
						)}
					</Button>
					<Button onClick={handleCancelDelete} color="primary">
						No
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default UploadPdf;

import React, { useEffect, useState } from "react";
import {
	Typography,
	List,
	ListItem,
	ListItemText,
	Link,
	Box,
	TextField,
	InputAdornment,
	IconButton,
} from "@mui/material";
import { Clear as ClearIcon } from "@mui/icons-material";
import { listS3FoldersAndUrls } from "../services/lambdaService"; // Ensure correct import path

// Function to clean the title and return as a string
const cleanTitle = (fileName: string): string => {
	let cleanedTitle = fileName
		.replace(/Author%3A|Author:|Author-/g, "")
		.replace(/Type%3A|Type:|Type-/g, "")
		.replace(/Year%3A|Year:|Year-/g, "")
		.replace(/Title%3A|Title:|Title-/g, "")
		.trim();

	// Ensure there are no leading periods or spaces
	cleanedTitle = cleanedTitle
		.replace(/[_\s]+/g, " ")
		.replace(/\s*\.\s*/g, ". ")
		.replace(/^\.\s*/, "");

	return cleanedTitle;
};

// Function to format the title and italicize everything after the third period
const formatTitle = (fileName: string): React.ReactNode => {
	const cleanedTitle = cleanTitle(fileName);

	const parts = cleanedTitle.split(".");
	if (parts.length > 3) {
		const normalPart = parts.slice(0, 3).join(". ");
		const italicPart = parts.slice(3).join(". ");

		return (
			<span>
				{normalPart}.<em>{italicPart}</em>
			</span>
		);
	} else {
		return <span>{cleanedTitle}</span>;
	}
};

// Function to filter PDF files based on keyword search
const filterFilesByKeyword = (
	files: Array<{ file_name: string; url: string }>,
	keyword: string
) => {
	if (!keyword) return files;

	const lowercasedKeyword = keyword.toLowerCase();

	return files.filter((file) => {
		const cleanedTitle = cleanTitle(file.file_name).toLowerCase();
		return cleanedTitle.includes(lowercasedKeyword);
	});
};

const SourcesPage: React.FC = () => {
	const [pdfFiles, setPdfFiles] = useState<
		Array<{ file_name: string; url: string }>
	>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [keyword, setKeyword] = useState<string>("");

	const fetchFoldersAndUrls = async () => {
		setLoading(true);
		try {
			const response = await listS3FoldersAndUrls();
			console.log("API Response:", response);

			const fetchedPdfFiles = response.pdf_files || [];
			setPdfFiles(fetchedPdfFiles);

			console.log("Fetched PDF Files:", fetchedPdfFiles);
		} catch (error) {
			console.error("Error fetching PDF files and URLs:", error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchFoldersAndUrls();
	}, []);

	const filteredPdfFiles = filterFilesByKeyword(pdfFiles, keyword);

	const handleClearSearch = () => {
		setKeyword("");
	};

	return (
		<Box
			sx={{
				padding: "16px",
				overflowY: "auto",
				height: "100%", // Ensure the SourcesPage takes up available height
			}}
		>
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<Typography
					variant="h6"
					gutterBottom
					sx={{
						cursor: "pointer",
						color: "#002255",
						"&:hover": {
							color: "#000a63",
							textDecoration: "underline",
						},
					}}
					onClick={fetchFoldersAndUrls} // Refresh on click
				>
					Policies ({filteredPdfFiles.length})
				</Typography>
				<Box display="flex" alignItems="center">
					<TextField
						label="Search"
						variant="outlined"
						size="small"
						value={keyword}
						onChange={(e) => setKeyword(e.target.value)}
						sx={{ marginRight: "16px", fontSize: "0.875rem" }}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="clear search"
										onClick={handleClearSearch}
										edge="end"
									>
										<ClearIcon fontSize="small" />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</Box>
			</Box>
			{loading ? (
				<Typography variant="body1">Loading...</Typography>
			) : (
				<List>
					{filteredPdfFiles.map((pdfFile, index) => (
						<ListItem key={index}>
							<ListItemText
								primary={
									<>
										{`${index + 1}. `}
										<Link
											href={pdfFile.url}
											target="_blank"
											rel="noopener noreferrer"
											sx={{
												color: "#002255",
												textDecoration: "none",
												"&:hover": {
													color: "#000a63",
												},
											}}
										>
											{formatTitle(pdfFile.file_name)}
										</Link>
									</>
								}
							/>
						</ListItem>
					))}
				</List>
			)}
		</Box>
	);
};

export default SourcesPage;

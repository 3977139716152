import React, { useState } from "react";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import {
	Box,
	Button,
	TextField,
	Typography,
	Paper,
	CircularProgress,
	Grid,
	Link,
} from "@mui/material";
import SourcesPage from "./SourcesPage";

interface ChatBoxProps {
	selectedDirectory: string;
	userEmail: string;
}

// Function to reverse the title cleaning process and format the citation
const formatCitation = (citation: string): string => {
	// Ensure citation is a string before processing
	const citationStr = String(citation);

	// Step 1: Remove everything after the last (fourth) period
	const parts = citationStr.split(".");
	const trimmedCitation = parts.slice(0, 4).join(".");

	// Step 2: Insert "Author:" at the very beginning
	let formattedCitation = `Author:${trimmedCitation}`;

	// Step 3: Insert "Type:" after the first period
	formattedCitation = formattedCitation.replace(/\./, ".Type:");

	// Step 4: Remove parentheses "(" and ")"
	formattedCitation = formattedCitation.replace(/[()]/g, "");

	// Step 5: Insert "Year:" before a four-digit number that starts with 20
	formattedCitation = formattedCitation.replace(/(20\d{2})/, "Year:$1");

	// Step 6: Insert "Title:" after the third period
	const sections = formattedCitation.split(".");
	if (sections.length > 3) {
		formattedCitation =
			sections.slice(0, 3).join(".") + ".Title:" + sections.slice(3).join(".");
	}

	// Step 7: Insert underscores "_" between words after the third period
	formattedCitation = formattedCitation.replace(
		/\.Title:([^.]*)/,
		(match, p1) => `.Title:${p1.replace(/\s+/g, "_")}`
	);

	// Step 8: Replace all periods with underscores "_"
	formattedCitation = formattedCitation.replace(/\./g, "_");

	// Step 9: Remove all whitespace
	formattedCitation = formattedCitation.replace(/\s+/g, "");

	// Step 10: Replace double underscores "__" with one underscore "_"
	formattedCitation = formattedCitation.replace(/__+/g, "_");

	// Step 11: Replace "Title:_" with "Title:"
	formattedCitation = formattedCitation.replace("Title:_", "Title:");

	// Step 12: Append ".pdf" at the end
	formattedCitation += ".pdf";

	return formattedCitation;
};

const ChatBox: React.FC<ChatBoxProps> = ({ selectedDirectory, userEmail }) => {
	const [query, setQuery] = useState("");
	const [response, setResponse] = useState("");
	const [mostRelevantCitations, setMostRelevantCitations] = useState<
		Array<{ citation: string; presignedUrl: string; similarity: number }>
	>([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);

	const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setQuery(event.target.value);
	};

	const handleQuerySubmit = async () => {
		if (!selectedDirectory) {
			console.error("No directory selected");
			setError("No directory selected");
			return;
		}

		setLoading(true);
		setError(null);

		try {
			const res = await axios.post(
				"https://c4ufhe0ncj.execute-api.us-east-1.amazonaws.com/prod/ask-question",
				{
					query,
					directory: `${selectedDirectory}/final_embedding.pkl`,
					email: userEmail,
				}
			);
			const data = res.data;
			console.log("API Response Data:", data);
			setResponse(data.answer);

			// Fetch presigned URLs for all relevant citations
			const citationPromises = (data.most_relevant_citation || []).map(
				async (item: {
					citation: string;
					similarity: number;
					presignedUrl: string;
				}) => {
					// Format the citation correctly before requesting the presigned URL
					const formattedCitation = formatCitation(item.citation);
					try {
						const urlRes = await axios.post(
							"https://c4ufhe0ncj.execute-api.us-east-1.amazonaws.com/prod/generate-presigned-url",
							{
								file_name: formattedCitation,
							}
						);
						return {
							citation: item.citation,
							presignedUrl: urlRes.data.url,
							similarity: item.similarity,
						};
					} catch (error) {
						console.error("Error generating presigned URL:", error);
						return {
							citation: item.citation,
							presignedUrl: "#",
							similarity: item.similarity,
						};
					}
				}
			);

			const citationsWithUrls = await Promise.all(citationPromises);
			setMostRelevantCitations(citationsWithUrls);
		} catch (error: any) {
			console.error("Error fetching response:", error);
			if (error.response) {
				// Server responded with a status other than 2xx
				console.error("Response data:", error.response.data);
				console.error("Response status:", error.response.status);
				console.error("Response headers:", error.response.headers);
				setError(
					`Error: ${error.response.status}. ${
						error.response.data.error || "An error occurred."
					}`
				);
			} else if (error.request) {
				// Request was made but no response was received
				console.error("Request data:", error.request);
				setError("No response received from the server.");
			} else {
				// Something else happened in setting up the request
				console.error("Error message:", error.message);
				setError(`Error: ${error.message}`);
			}
		} finally {
			setLoading(false);
		}
	};

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			handleQuerySubmit();
		}
	};

	const handleClear = () => {
		setQuery("");
		setResponse("");
		setMostRelevantCitations([]);
		setError(null);
	};

	return (
		<Grid
			container
			spacing={2}
			sx={{
				flexDirection: { xs: "column", md: "row" },
				height: "100%",
			}}
		>
			<Grid
				item
				xs={12}
				md={8}
				sx={{
					display: "flex",
					flexDirection: "column",
					overflow: "hidden",
					maxHeight: { xs: "50vh", md: "none" },
				}}
			>
				<Paper
					elevation={3}
					sx={{
						p: 2,
						flex: 1,
						display: "flex",
						flexDirection: "column",
						overflowY: "auto",
					}}
				>
					<TextField
						label="Ask your policy question here..."
						variant="outlined"
						fullWidth
						multiline
						rows={2}
						maxRows={3}
						value={query}
						onChange={handleQueryChange}
						onKeyPress={handleKeyPress}
						disabled={loading}
						sx={{
							"& .MuiOutlinedInput-root": {
								"& fieldset": {
									borderColor: "gray",
								},
								"&:hover fieldset": {
									borderColor: "#002255",
								},
								"&.Mui-focused fieldset": {
									borderColor: "#002255",
								},
							},
							"& .MuiInputLabel-root": {
								color: "#002255",
							},
							"& .MuiInputLabel-root.Mui-focused": {
								color: "#002255",
							},
						}}
					/>
					<Box display="flex" gap={2} mt={2}>
						<Button
							variant="contained"
							sx={{
								backgroundColor: "#002255",
								"&:hover": {
									backgroundColor: "#000a63",
								},
								borderRadius: "20px",
							}}
							onClick={handleQuerySubmit}
							disabled={loading || query.trim() === ""}
							aria-label="Submit your question"
						>
							{loading ? (
								<CircularProgress size={24} sx={{ color: "#fff" }} />
							) : (
								"Submit"
							)}
						</Button>
						<Button
							variant="contained"
							sx={{
								backgroundColor: "#002255",
								"&:hover": {
									backgroundColor: "#000a63",
								},
								borderRadius: "20px",
							}}
							onClick={handleClear}
							disabled={loading}
							aria-label="Clear the query"
						>
							Clear
						</Button>
					</Box>
					{error && (
						<Typography variant="body2" color="error" mt={2}>
							{error}
						</Typography>
					)}
					{response && (
						<Box mt={2}>
							<ReactMarkdown>{response}</ReactMarkdown>
						</Box>
					)}
					{mostRelevantCitations.length > 0 && (
						<Box mt={2}>
							<Typography variant="h6">Sources</Typography>
							{mostRelevantCitations.map((citation, index) => (
								<Typography
									key={index}
									variant="body2"
									sx={{
										whiteSpace: "pre-wrap",
										color: "#002255",
									}}
								>
									{index + 1}.{" "}
									<Link
										href={citation.presignedUrl || "#"}
										target="_blank"
										rel="noopener noreferrer"
										sx={{
											color: "#002255",
											textDecoration: "none",
											"&:hover": {
												color: "#000a63",
											},
										}}
									>
										{citation.citation}
									</Link>
									<Typography variant="caption" display="block">
										Similarity Score: {citation.similarity.toFixed(2)}
									</Typography>
								</Typography>
							))}
						</Box>
					)}
				</Paper>
			</Grid>
			<Grid
				item
				xs={12}
				md={4}
				sx={{
					display: "flex",
					flexDirection: "column",
					overflow: "hidden",
					maxHeight: { xs: "50vh", md: "none" },
				}}
			>
				<Paper
					elevation={3}
					sx={{
						p: 2,
						flex: 1,
						overflowY: "auto",
					}}
				>
					<SourcesPage />
				</Paper>
			</Grid>
		</Grid>
	);
};

export default ChatBox;

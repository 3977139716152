import axios from "axios";
const API_URL = "https://c4ufhe0ncj.execute-api.us-east-1.amazonaws.com/prod";

export const listS3FoldersAndUrls = async () => {
	try {
		const response = await axios.get(`${API_URL}/list-embeddings`);
		return response.data;
	} catch (error) {
		console.error("Error in listS3FoldersAndUrls:", error);
		throw error;
	}
};
